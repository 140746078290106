import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import PrimeVue from "primevue/config";
import store from "./store";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import ToastService from 'primevue/toastservice';
Vue.use(ToastService);
Vue.use(PrimeVue)


import VueImg from 'v-img';

Vue.use(VueImg);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  PrimeVue,
  vuetify,
  render: h => h(App)
}).$mount('#app')
