import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  // },
  // {
  //   path: '/registrar-usuarios',
  //   name: 'registrar-usuarios',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/RegistrarClientes.vue')
  // },
  // {
  //   path: '/consultar-usuario',
  //   name: 'consultar-usuario',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/ConsultarUsuario.vue')
  // },
  // {
  //   path: '/rtm-dia',
  //   name: 'rtm-dia',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/RtmDia.vue')
  // },
  // {
  //   path: '/encuesta',
  //   name: 'Encuesta',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Encuesta.vue')
  // },
  {
    path: '/',
    name: 'Encuesta',
    component: () => import(/* webpackChunkName: "about" */ '../views/Encuesta.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
